import React from 'react'
import PropTypes from 'prop-types'

import Content from 'components/Content'

const Author = ({ language, fullText }) => {
  return (
    <Content>
      <div itemScope itemType='http://schema.org/Person'>
        <h1>Selam!</h1>

        <p>
          Ben <span itemProp='name'>Hamza</span> KAYA, {' '}
          <span itemProp='jobTitle'>Front-End Developer</span> olarak
          çalışıyorum. Açık kaynak projeleri konusunda tutkuluyum, bilginin ve
          iyi fikirlerin yayılmasının dünyaya ilham verdiğine ve geliştirdiğine
          inanıyorum.
        </p>
      </div>
    </Content>
  )
}

Author.propTypes = {
  language: PropTypes.string,
  fullText: PropTypes.bool,
}

export default Author
